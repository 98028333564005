import React from "react";
import { navigate, useIntl, FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl";

import * as constants from "../../app.constant";
import AppLayout from "../../components/shared/AppLayout";
import successIcon from "../../assets/images/success-icon2.png";

const PageUploadSuccess = () => {
    const intl = useIntl();
    const messages = intl.messages;

    const goToActivationSuccessPage = () => {
        navigate(constants.ROUTES.ACTIVATION_SUCCESS);
    };

    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} hasFooter={true}>
            <div className="app-page page-upload-success">

                <div className="upload-success">
                    <div className="upload-success__container container">
                        <img className="upload-success__icon img-fluid" src={successIcon} alt="" />

                        <p className="upload-success__title text-bold">
                            <FormattedMessage id="uploadSuccess.title" />
                        </p>
                        
                        <p className="upload-success__instructions">
                            <FormattedHTMLMessage id="uploadSuccess.instructions" values={{callCenterNumber: messages["common.callCenter.number"], callCenterLink: messages["common.callCenter.link"] }} />
                        </p>

                        <p className="btn-container btn-container--center">
                            <button type="button" className="btn btn-lg btn-primary" onClick={goToActivationSuccessPage}>
                                <FormattedMessage id="uploadSuccess.cta" />
                            </button>
                        </p>
                    </div>
                </div>

            </div>
        </AppLayout>
    );
};

export default PageUploadSuccess;
